import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {

  constructor(
  ) {
    super();
    this.setBaseCollection('products');
  }

}

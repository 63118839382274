import { Injectable, Injector } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SharedModule } from '../shared/shared.module';
import { HelperService } from '../shared/service/helper.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  collectionName: string;
  protected db = SharedModule.injector.get(AngularFirestore);
  protected helper = SharedModule.injector.get(HelperService);

  constructor(
  ) { }

  setBaseCollection(collectionName: string) {
    this.collectionName = collectionName;
  }

  getList() {
    return this.db.collection(this.collectionName).snapshotChanges().pipe(
      switchMap((res: any) => {
        return of(res.map(item => {
          const data: any = item.payload.doc.data();
          return {
            id: item.payload.doc.id,
            ...data
          };
        })) as any;
      })
    );
  }

  create(data) {
    const result = this.helper.removeIdFromObject(data);
    return this.db.collection(this.collectionName).add(result);
  }

  update(data) {
    const result = this.helper.removeIdFromObject(data);
    return this.db.collection(this.collectionName).doc(data.id).set(result);
  }

  remove(id) {
    return this.db.collection(this.collectionName).doc(id).delete();
  }

}

import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends BaseService {

  constructor(
  ) {
    super();
    this.setBaseCollection('config');
  }

}

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA-y5Ihap1nCWGR9qTDIHacIyJvsQiAprM',
    authDomain: 'filtrodescal-6df75.firebaseapp.com',
    databaseURL: 'https://filtrodescal-6df75.firebaseio.com',
    projectId: 'filtrodescal-6df75',
    storageBucket: 'filtrodescal-6df75.appspot.com',
    messagingSenderId: '705271534100',
    appId: '1:705271534100:web:ef70c9ef33dd3a0184cd01',
    measurementId: 'G-BL2180WBJZ'
  }
};

import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  scrollTab = new EventEmitter();
  openCart = new EventEmitter();

  constructor() {
  }

  removeIdFromObject(obj) {
    const req = {};
    for (const key in obj) {
      if (key === 'id') {
        continue;
      }
      if (typeof key === 'object' && obj[key] && obj[key].id) {
        continue;
      }
      req[key] = obj[key];
    }
    return req;
  }

  sortByName(list) {
    return list.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {
      numeric: true,
      sensitivity: 'base'
    }));
  }

  sortByIndex(list) {
    return list.sort((a, b) => a.index > b.index ? 1 : -1);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, Injector, NgModule } from '@angular/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AppComponent } from './app.component';
import { CURRENT_DATA, SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BehaviorSubject } from 'rxjs';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    SweetAlert2Module.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: CURRENT_DATA,
      useValue: new BehaviorSubject(''),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

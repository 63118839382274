import { NgModule, Injectable, Injector, InjectionToken } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TRANSLOCO_CONFIG, TranslocoModule, Translation, TRANSLOCO_LOADER, TranslocoLoader  } from '@ngneat/transloco';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Ng2ImgMaxModule } from 'ng2-img-max';

@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export const CURRENT_DATA = new InjectionToken<any>('currentData');

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule,
    NgxSpinnerModule,
    CommonModule,
    RouterModule,
    SweetAlert2Module,
    Ng2ImgMaxModule,
    ModalModule.forRoot(),
  ],
  exports: [
    AngularFireModule,
    TranslocoModule,
    NgxSpinnerModule,
    SweetAlert2Module,
    RouterModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    Ng2ImgMaxModule,
    ModalModule
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        reRenderOnLangChange: true,
        availableLangs: ['en', 'es'],
        prodMode: environment.production,
        defaultLang: 'en'
      }
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: HttpLoader
    }
  ]
})
export class SharedModule {
  static injector: Injector;

  constructor(injector: Injector) {
    SharedModule.injector = injector;
  }
}

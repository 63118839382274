import { Component, Inject, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductService } from './services/product.service';
import { CURRENT_DATA } from './shared/shared.module';
import { SwalService } from './shared/service/swal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactService } from './services/contact.service';
import { switchMap } from 'rxjs/operators';
import { ProductModel } from './models/product.model';
import { ContactModel } from './models/contact.model';
import { HelperService } from './shared/service/helper.service';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigService } from 'src/app/services/config.service';
import { ConfigModel } from 'src/app/models/config.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(CURRENT_DATA) private currentDataSubject: BehaviorSubject<any>,
    private spinner: NgxSpinnerService,
    private swalService: SwalService,
    private helper: HelperService,
    private translocoService: TranslocoService,

    private configService: ConfigService,
    private productService: ProductService,
    private contactService: ContactService
  ) {
  }

  ngOnInit() {
    let products: Array<ProductModel>;
    let contacts: Array<ContactModel>;
    let config: ConfigModel;

    this.spinner.show();
    this.productService.getList().pipe(
      switchMap((res: Array<ProductModel>) => {
        products = this.helper.sortByIndex(res);
        return this.configService.getList();
      }),
      switchMap((res: Array<ConfigModel>) => {
        config = res[0];
        return this.contactService.getList();
      })
    ).subscribe((res: Array<ContactModel>) => {
      this.spinner.hide();
      contacts = this.helper.sortByName(res);

      this.currentDataSubject.next({
        products,
        contacts,
        config
      });
    }, () => {
      this.spinner.hide();
      this.swalService.openErrorMessage(
        this.translocoService.translate('common.error'),
        this.translocoService.translate('common.server_error'),
      );
    });
  }

}

import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor() { }

  openErrorMessage(title, text) {
    Swal.fire({
      title,
      text,
      type: 'error'
    });
  }

  openSuccessMessage(title, text) {
    return Swal.fire({
      title,
      text,
      type: 'success'
    });
  }

  openConfirmMessage(text, title) {
    return Swal.fire({
      title,
      text,
      type: 'warning',
      confirmButtonText: 'OK',
      showCancelButton: true,
      cancelButtonText: 'cancel'
    });
  }

}
